<template>
  <div id="app">
    <main-view></main-view>
  </div>
</template>

<script>
import MainView from './components/MainView'

export default {
  name: 'App',
  components: {
    MainView
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color:rgba(255,255,255,1);
}
h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #333333;
}
ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
li {
  list-style: none;
}
</style>
