<template>
  <div class="MainView">
    <div class="imgs"><img src="@/assets/main.png" alt=""></div>
    <div class="main">
      <img src="@/assets/logo.png" alt="">
      <h1>人民德育心理素养云平台</h1>
      <span>请选择登陆方式</span>
      <ul>
        <li v-for="(item, index) in list" :key="index" :class="active === index ? 'active' : ''" @click="handleClick(item.links, index)" @mouseenter="enter(index)" @mouseleave="leave">
          <img :src="active === index ? item.imgActive : item.imgNormal" alt="">{{item.name}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainView',
  data () {
    return {
      active: null,
      list: [
        {
          name: '教育局登陆',
          imgNormal: require('@/assets/icon_normal_1.png'),
          imgActive: require('@/assets/icon_active_1.png'),
          links: 'http://xljk.peopledy.com/peopledyEducationPC/index.html#/'
        },
        {
          name: '学校登陆',
          imgNormal: require('@/assets/icon_normal_2.png'),
          imgActive: require('@/assets/icon_active_2.png'),
          links: 'http://xljk.peopledy.com/peopledyTeacherPC20/index.html#/'
        },
        {
          name: '学生登陆',
          imgNormal: require('@/assets/icon_normal_3.png'),
          imgActive: require('@/assets/icon_active_3.png'),
          links: 'http://xljk.peopledy.com/peopledyStuPC/index.html#/'
        }
      ]
    }
  },
  methods: {
    leave () {
      this.active = null
    },
    enter (index) {
      this.active = index
    },
    handleClick (url, index) {
      this.active = index
      window.location.href = url
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.MainView {
  width: 100%;
  height: 100%;
  display: flex;
  color: #333333;
}
.MainView > div {
  height: 100%;
}
.MainView .imgs {
  height: 100%;
  display: flex;
}
.MainView .imgs img {
  width: auto;
  height: 100%;
}
.MainView .main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px 20px;
  box-sizing: border-box;
}
.MainView .main > img {
  position: absolute;
  height: auto;
}
.MainView .main span {
  color: #999999;
}
.MainView .main ul {
  display: flex;
  flex-direction: column;
}
.MainView .main ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.12);
  border-radius: 52px;
  cursor: pointer;
  transition: all 0.2s;
}
.MainView .main ul li:hover {
  background: #297FFA;
  color: #FFFFFF;
}
.MainView .main ul li.active {
  background: #297FFA;
  color: #FFFFFF;
}
@media screen and (max-width: 1280px) {
  .MainView .main > img {
    top: 50px;
    right: 50px;
    width: 120px;
  }
  .MainView .main h1 {
    font-size: 27px;
    margin-bottom: 20px;
  }
  .MainView .main span {
    font-size: 19px;
  }
  .MainView .main ul {
    margin-top: 50px;
  }
  .MainView .main ul li {
    width: 319px;
    margin-bottom: 27px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 21px;
  }
  .MainView .main ul li img {
    width: 45px;
    margin-right: 7px;
  }
}
@media screen  and (min-width: 1281px) and (max-width: 1440px) {
  .MainView .main > img {
    top: 60px;
    right: 60px;
    width: 135px;
  }
  .MainView .main h1 {
    font-size: 30px;
    margin-bottom: 23px;
  }
  .MainView .main span {
    font-size: 21px;
  }
  .MainView .main ul {
    margin-top: 57px;
  }
  .MainView .main ul li {
    width: 359px;
    margin-bottom: 30px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 24px;
  }
  .MainView .main ul li img {
    width: 51px;
    margin-right: 8px;
  }
}
@media screen  and (min-width: 1441px) {
  .MainView .main > img {
    top: 80px;
    right: 80px;
    width: 180px;
  }
  .MainView .main h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .MainView .main span {
    font-size: 28px;
  }
  .MainView .main ul {
    margin-top: 77px;
  }
  .MainView .main ul li {
    width: 478px;
    margin-bottom: 40px;
    padding-top: 19px;
    padding-bottom: 19px;
    font-size: 32px;
  }
  .MainView .main ul li img {
    width: 68px;
    margin-right: 10px;
  }
}
</style>
